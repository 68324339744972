body, html {
  margin: 0;
  background: black;
  color: white;
  font-size: calc(10px + 0.5vw);
  overflow: hidden;
  position: absolute;
}

.Interactions {
  position: fixed;
  z-index: 200;
}

.App {
  position: fixed;
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  padding: calc(-1 * env(safe-area-inset-top)) calc(env(safe-area-inset-right)) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.App-border {
  box-sizing: border-box;
  border: 1vw solid black;
  position: absolute;
  pointer-events: none;
}

.Loading {
  width: 100%;
  height: 100%;
}

.Loading.mod-loader {
  background: #000 url('/spinner.gif');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 2.5em 2.5em;
}

.Loading div {
  position: relative;
  top: calc(50% + 2.5em);
  left: 50%;
  transform: translate(-50%, 0);
  width: 70vw; /* Need a specific value to work */
  text-align: center;
}
